<template>
	<div class="main-contents input">
		<div class="tit">기술인재 투입<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="movePage()"/><img src="/images/admin/human_input.png" alt="선택한기술인재투입1" @click="addTecMber('1')" v-if="div == '1'"/><img class="change" src="/images/admin/change_input_btn.png" alt="선택한기술인재투입2" @click="addTecMber('2')" v-else/></div>
		<div class="search-box special">
			<SelectComp tagName="div" class="wd01" title="평균평가등급" v-model="input.evalGradeCd" cdId="PRO126" :isAll="true"/><nbsp/>
			<SelectComp tagName="div" class="wd02" title="성별" v-model="input.gender" list="M:남,F:여" :isAll="true"/><nbsp/>
			<SelectComp tagName="div" class="wd03" title="직원구분" v-model="input.empDivCd" cdId="PRO102" :isAll="true"/><nbsp/>
			<SelectComp tagName="div" class="wd03" title="최종학력" v-model="input.schoolDivCd" cdId="PRO121" :isAll="true"/><nbsp/>
			<SelectComp tagName="div" class="wd04" title="경력" v-model="input.careerTermCd" cdId="PRO107" :isAll="true"/><nbsp/>
			<SelectComp tagName="div" class="wd05" title="기술등급_학력" v-model="input.resumeSchoolTechGradeCd" cdId="PRO106" :isAll="true"/><nbsp/>
			<SelectComp tagName="div" class="wd05" title="기술등급_자격" v-model="input.resumeLicenseTechGradeCd" cdId="PRO106" :isAll="true"/><nbsp/>
			<SelectComp tagName="div" class="wd03" title="참여구분" v-model="input.joinStatusCd" list="0:대기중,1:참여중,2:참여예정,3:인터뷰중" :isAll="true"/><nbsp/>
			<SelectComp tagName="div" class="wd06" title="검색조건" v-model="input.searchType" :list="searchType" defValue="NAME"/>
			<InputComp :title="searchType[input.searchType]" v-model="input.searchTxt" @keydown.enter="getList('1')"/>
			<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getList('1')"/>
		</div>
		<!-- 전체프로젝트 리스트 -->
		<div class="Board type3">
			<table class="Board_type3 admin special">
				<colgroup>
					<col width="3%">
					<col width="3%">
					<col width="5%">
					<col width="5%">
					<col width="5%">
					<col width="13%">
					<col width="3%">
					<col width="3%">
					<col width="18%">
					<col width="5%">
					<col width="5%">
					<col width="7%">
					<col width="6%">
					<col width="6%">
					<col width="7%">
					<col width="10%">
				</colgroup>
				<thead>
					<tr>
						<th>선택</th>
						<th>NO</th>
						<th><span class="ivl13">평</span>균<br/>평가등급</th>
						<th>회원명</th>
						<th>회원번호</th>
						<th>아이디</th>
						<th>성별</th>
						<th>나이</th>
						<th>소속</th>
						<th>직원구분</th>
						<th>최종학력</th>
						<th>경력</th>
						<th>기술등급<br/>(학력기준)</th>
						<th>기술등급<br/>(자격기준)</th>
						<th>프로젝트<br/>참여구분</th>
						<th class="resume">기술인재 종합정보</th>
					</tr>
				</thead>
				<tbody>
					<template v-if="list && list.length > 0">
						<tr v-for="(item, idx) in list" :key="item.mberSeq">
							<td class="radio">
								<SelectComp type="radio" v-model="seletedIdx" :list="item.mberSeq+ ':'" @change="resumeCheck(item)"/>
							</td>
							<td class="score">{{(pageInfo.pageUnit * (pageInfo.pageIndex-1)) + idx + 1}}</td>
							<td class="score">
								<grade-img type="TOT_EVAL_ADM" :grade="item.totEvalGradeCd" :specialYn="item.specialGradeYn">
									<template v-slot:smark><div class="s-mark"><img src="/images/admin/s-mark.png" alt="스페셜마크"></div></template>
								</grade-img>
							</td>
							<td class="score">{{item.mberNm}}</td>
							<td class="score">{{item.mberSeq}}</td>
							<td class="score">{{item.loginId}}</td>
							<td class="score">{{item.gender | gender}}</td>
							<td class="score">{{item.birthYyyymmdd | birthYear}}</td>
							<td class="score">{{item.corpNm}}</td>
							<td class="score"><SelectComp type="text" :value="item.empDivCd" cdId="PRO102"/></td>
							<td class="score"><SelectComp  type="text" :value="item.schoolDivCd" cdId="PRO121"/></td>
							<td class="score">{{item.totWorkMonthCnt | months}}</td>
							<td class="score"><grade-img type="SCHOOL_TECH_ADM"  :grade="item.resumeSchoolTechGradeCd"/></td>
							<td class="score"><grade-img type="LICENSE_TECH_ADM" :grade="item.resumeLicenseTechGradeCd"/></td>
							<td class="score">
								<template v-if="item.joinNowYn    == 'Y'"><div class="state pf"></div>참 여 중</template>
								<template v-if="item.joinBeforeYn == 'Y'"><div class="state ep"></div>참여예정</template>
								<template v-if="item.interviewYn  == 'Y'"><img class="itv" src="/images/admin/itv_icon.png" alt="인터뷰마크"/>인터뷰중</template>
							</td>
							<td class="score resume"><div class="btn" @click="goDetail(item)">정보보기 &#9654;</div></td>
						</tr>
					</template>
					
					<!-- 관련내용 없을 경우 -->
					<template v-else>
						<tr>
							<td colspan="16" class="none">기술인재가 없습니다!</td>
						</tr>
					</template>
				</tbody>
			</table>
		</div>

		<!-- 페이징부분 -->
		<PagingComp :pageInfo="pageInfo" @page-click="goPage"/>

	</div>
</template>

<script>
import PagingComp from '@/components/PagingComp.vue';
import GradeImg from "@/components/highpro/GradeImg.vue";

export default {
	data() {
		return {
			input : {
				evalGradeCd:'',
				gender:'',
				empDivCd:'',
				schoolDivCd:'',
				careerTermCd:'',
				resumeSchoolTechGradeCd: '',
				resumeLicenseTechGradeCd: '',
				joinStatusCd: '',
				searchType:'NAME',
				searchTxt: '',
			},
			tecMberSeq: '',
			selectedRow : '',
			seletedIdx : '',
			list : {},
			pageInfo : {},
			searchType: {'NAME':'기술인재명', 'CORP':'소속회사명'},

			checkIdx : '',

			projectSeq : this.$route.query.projectSeq,
			projectNm : this.$route.params.projectNm,
			div : this.$route.params.div || this.$route.query.div,

			// 교체투입
			targetMberSeq : this.$route.params.targetMberSeq || this.$route.query.targetMberSeq,
			targetCorpNm : this.$route.params.corpNm || this.$route.query.corpNm,
			failedReason : this.$route.params.failedReason || this.$route.query.failedReason,
			
		}
	},

	components : {
		PagingComp, GradeImg
	},

	mounted() {
		//console.log('route', this.$route);

		var keys = Object.keys(this.input);
		for(var key in this.$route.params){
			if(keys.indexOf(key) >= 0){
				this.input[key] = this.$route.params[key];
			}
		}			

		this.getList();
	},
	methods : {
        resumeCheck(item) {
			if(item.resumeSchoolTechGradeCd == '' || item.resumeSchoolTechGradeCd == null) {
				alert("이력서 미등록 회원은 투입할 수 없습니다.");
				this.seletedIdx = this.checkIdx;
			} else {
				this.selectedRow = item;
				this.checkIdx = item.mberSeq;
			}
		}
		,
		getList(div, isScroll = false){
			if(div) this.input.pageIndex = 1;
			
            this.$.httpPost('/api/mem/adm/tec/getTecMemberFinalList', this.input)
                .then(res => {
					//console.log('res.data.list', res.data.list);
                    this.list = res.data.list;
                    this.pageInfo = res.data.pageInfo;
                    if(isScroll) {
						window.scroll(0, 0);
					}
                }).catch(this.$.httpErrorCommon);
        },

		// 페이징 이동
		goPage(page) {
			this.input.pageIndex = page;
			this.getList();
		},

		goDetail(item){
			//console.log('goResume', item);
			var query = {};

			var params = {
				caller : {
					name: this.$route.name,
					params: this.input,
				}
			}

			if(this.div == '1') {
				query.tecMberSeq = item.mberSeq;
				query.projectSeq = this.projectSeq;
				query.div = '1';
			}else {
				// query.tecMberSeq = this.selectedRow.mberSeq;
				query.tecMberSeq = item.mberSeq;
				query.targetMberSeq = this.targetMberSeq;
				query.failedReason = this.failedReason;
				query.projectSeq = this.projectSeq;
				query.div = '2';
			}

			this.$router.push({name: 'MEM912M02', query, params });
		},

		movePage() {
			// console.log("param "+this.$route.query.projectSeq);
			var params = {};
			params.prjSeq = this.$route.query.projectSeq;
			// this.$router.go(-1, param);
			this.$router.push({name: 'PRJ911M01', params});
		},

		addTecMber(div) {
			if(!this.selectedRow) {
				alert('투입할 기술인재를 선택해주세요.');
				return false;
			}

			var param = {};
			
			if(div == '1') {
				param = {
					tecMberSeq : this.selectedRow.mberSeq,
					projectSeq : this.projectSeq,
					totEvalGradeCd : this.selectedRow.totEvalGradeCd,
					specialGradeYn : this.selectedRow.specialGradeYn,
				}

				this.$.popup('/adm/prj/PRJ911P05', param);
			}else {
				param.changeMberSeq = this.selectedRow.mberSeq;
				param.changeMberTotalEvalGrade = this.selectedRow.totEvalGradeCd;
				param.targetMberSeq = this.targetMberSeq;
				param.targetCorpNm = this.targetCorpNm;
				param.failedReason = this.failedReason;
				param.projectSeq = this.projectSeq;
				param.type = '2';

				//console.log('PRJ911P04', param);
				this.$.popup('/adm/prj/PRJ911P04', param);
			}
		}
	}
}
</script>